// src/components/TeamsChannelsPicker.js

import React, { useState, useEffect } from 'react';

// Logo & loading (optional)
import logo from './logo.jpg'; // Adjust path if needed
import loadingGif from '../components/gifs/loading.gif'; // Adjust path if needed

// PrimeReact DataTable + Column
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import GraphService from '../utils/GraphService';
import { getAccessToken } from '../utils/auth';

// Import piexifjs
import piexif from 'piexifjs';

const graphService = new GraphService();

const TeamsChannelsPicker = () => {
  // ---------------------------------------------------------
  // 1) TEAMS STATE
  // ---------------------------------------------------------
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [teamSearch, setTeamSearch] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);

  // ---------------------------------------------------------
  // 2) CHANNELS STATE
  // ---------------------------------------------------------
  const [channels, setChannels] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [channelSearch, setChannelSearch] = useState('');
  const [selectedChannel, setSelectedChannel] = useState(null);

  // ---------------------------------------------------------
  // 3) FOLDERS/FILES STATE
  // ---------------------------------------------------------
  const [folderItems, setFolderItems] = useState([]);
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [folderSearch, setFolderSearch] = useState('');
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [driveId, setDriveId] = useState(null);
  const [folderId, setFolderId] = useState(null);

  // ---------------------------------------------------------
  // 4) FILE UPLOAD STATE
  // ---------------------------------------------------------
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadStepVisible, setUploadStepVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // NEW: interval ID for our periodic file-check
  const [checkIntervalId, setCheckIntervalId] = useState(null);

  // NEW: For the "uploading" animation dots
  const [uploadingDots, setUploadingDots] = useState(0);

  // ---------------------------------------------------------
  //  Helper function to check for conflicts
  // ---------------------------------------------------------
  const setConflictFlags = (fileObjs, folderItems) => {
    return fileObjs.map((fileObj) => {
      // Build final name
      const newFullName = fileObj.extension
        ? `${fileObj.baseName}.${fileObj.extension}`
        : fileObj.baseName;

      // Check if name already exists in the folder
      const conflict = folderItems.some(
        (item) => item.name.toLowerCase() === newFullName.toLowerCase()
      );

      // Return a new object with conflict property set
      return { ...fileObj, conflict };
    });
  };

  // ---------------------------------------------------------
  //  On mount => fetch teams
  // ---------------------------------------------------------
  useEffect(() => {
    fetchTeams();

    // Cleanup any leftover interval if user navigates away
    return () => {
      if (checkIntervalId) {
        clearInterval(checkIntervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ---------------------------------------------------------
  //  Animate the "uploading..." text
  // ---------------------------------------------------------
  useEffect(() => {
    const interval = setInterval(() => {
      // This cycles 0 -> 1 -> 2 -> 0 -> 1 -> ...
      setUploadingDots((prev) => (prev + 1) % 3);
    }, 600); // speed in ms
    return () => clearInterval(interval);
  }, []);

  const getUploadingText = () => {
    // 0 => "uploading."
    // 1 => "uploading.."
    // 2 => "uploading..."
    return `uploading${'.'.repeat(uploadingDots + 1)}`;
  };

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken(['Team.ReadBasic.All']);
      const response = await fetch('https://graph.microsoft.com/v1.0/me/joinedTeams', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch Teams: ${response.statusText}`);
      }
      const data = await response.json();
      setTeams(data.value || []);
    } catch (error) {
      console.error('Error fetching Teams:', error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------
  //  Filter teams
  // ---------------------------------------------------------
  useEffect(() => {
    applyTeamsFilter();
  }, [teams, teamSearch]);

  const applyTeamsFilter = () => {
    const filtered = teams.filter((t) =>
      t.displayName.toLowerCase().includes(teamSearch.toLowerCase())
    );
    filtered.sort((a, b) =>
      a.displayName.localeCompare(b.displayName, undefined, { numeric: true })
    );
    setFilteredTeams(filtered);
  };

  // ---------------------------------------------------------
  //  On team row select => fetch channels
  // ---------------------------------------------------------
  const onTeamSelect = (e) => {
    const team = e.value;
    setSelectedTeam(team);
    fetchChannelsForTeam(team.id);
  };

  const fetchChannelsForTeam = async (teamId) => {
    if (!teamId) return;
    setLoading(true);
    try {
      const token = await getAccessToken(['Channel.ReadBasic.All']);
      const response = await fetch(`https://graph.microsoft.com/v1.0/teams/${teamId}/channels`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch channels: ${response.statusText}`);
      }
      const data = await response.json();
      setChannels(data.value || []);
    } catch (error) {
      console.error('Error fetching channels:', error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------
  //  Filter & sort Channels
  // ---------------------------------------------------------
  useEffect(() => {
    applyChannelsFilter();
  }, [channels, channelSearch]);

  const applyChannelsFilter = () => {
    const filtered = channels.filter((c) =>
      c.displayName.toLowerCase().includes(channelSearch.toLowerCase())
    );
    filtered.sort((a, b) =>
      a.displayName.localeCompare(b.displayName, undefined, { numeric: true })
    );
    setFilteredChannels(filtered);
  };

  // ---------------------------------------------------------
  //  On channel select => fetch root folder
  // ---------------------------------------------------------
  const onChannelSelect = (e) => {
    const channel = e.value;
    setSelectedChannel(channel);
    fetchChannelRootFolder(selectedTeam.id, channel.id);
  };

  const fetchChannelRootFolder = async (teamId, channelId) => {
    if (!teamId || !channelId) return;
    setLoading(true);
    try {
      const token = await getAccessToken(['Files.ReadWrite.All']);
      const resp = await fetch(
        `https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}/filesFolder`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (!resp.ok) {
        throw new Error(`Failed to fetch channel root folder: ${resp.statusText}`);
      }
      const folderData = await resp.json();
      const { id, parentReference } = folderData;
      const drive = parentReference?.driveId || null;

      // Store drive & folder info
      setDriveId(drive);
      setFolderId(id);
      setSelectedFolder(folderData);

      // Fetch children of this folder
      fetchFolderChildren(drive, id);
    } catch (error) {
      console.error('Error fetching channel root folder:', error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------
  //  Fetch children of folder
  // ---------------------------------------------------------
  const fetchFolderChildren = async (theDriveId, theFolderId) => {
    if (!theDriveId || !theFolderId) return;
    setLoading(true);
    try {
      const token = await getAccessToken(['Files.ReadWrite.All']);
      const url = `https://graph.microsoft.com/v1.0/drives/${theDriveId}/items/${theFolderId}/children`;
      const resp = await fetch(url, { headers: { Authorization: `Bearer ${token}` } });
      if (!resp.ok) {
        throw new Error(`Failed to fetch folder items: ${resp.statusText}`);
      }
      const data = await resp.json();
      setFolderItems(data.value || []);
    } catch (error) {
      console.error('Error fetching folder children:', error);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------------------------------------------
  //  Filter Folders (and files)
  // ---------------------------------------------------------
  useEffect(() => {
    applyFolderFilter();
  }, [folderItems, folderSearch]);

  const applyFolderFilter = () => {
    const filtered = folderItems.filter((f) =>
      f.name.toLowerCase().includes(folderSearch.toLowerCase())
    );
    filtered.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true })
    );
    setFilteredFolders(filtered);
  };

  // ---------------------------------------------------------
  //  On folder select => drill down if folder
  // ---------------------------------------------------------
  const onFolderSelect = (e) => {
    const item = e.value;
    // If it's a folder, drill down
    if (item.folder) {
      fetchFolderChildren(item.parentReference?.driveId, item.id);
    }
    setSelectedFolder(item);
    setFolderId(item.id);
  };

  // ---------------------------------------------------------
  //  Navigate to upload step
  // ---------------------------------------------------------
  const goToUploadStep = () => {
    setUploadStepVisible(true);
  };

  // ---------------------------------------------------------
  //  File selection with EXIF GPS embedding
  // ---------------------------------------------------------
  const handleFileSelection = async (event) => {
    const files = Array.from(event.target.files);

    // Get user's current GPS location
    let gpsData = null;
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = position.coords;
      gpsData = { latitude, longitude };
    } catch (error) {
      console.error('Error getting geolocation:', error);
      alert('Unable to retrieve GPS data. Photos will be uploaded without location information.');
    }

    // Helper function to convert decimal degrees to DMS Rational
    const degToDMSRational = (deg) => {
      const absolute = Math.abs(deg);
      const degrees = Math.floor(absolute);
      const minutesNotTruncated = (absolute - degrees) * 60;
      const minutes = Math.floor(minutesNotTruncated);
      const seconds = Math.round((minutesNotTruncated - minutes) * 60 * 100) / 100;

      return [
        [degrees, 1],
        [minutes, 1],
        [Math.floor(seconds * 100), 100],
      ];
    };

    // Process each file to embed GPS data
    const filesWithMetadata = await Promise.all(
      files.map(async (file) => {
        let modifiedFile = file;
        if (gpsData) {
          try {
            const reader = new FileReader();
            const fileData = await new Promise((resolve, reject) => {
              reader.onload = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(file);
            });

            // Extract the base64 part
            const base64Data = fileData.split(',')[1];
            const exifObj = { GPS: {} };

            // Convert latitude and longitude to DMS (degrees, minutes, seconds)
            const latDMS = degToDMSRational(gpsData.latitude);
            const lonDMS = degToDMSRational(gpsData.longitude);

            exifObj.GPS[piexif.GPSIFD.GPSLatitudeRef] = gpsData.latitude >= 0 ? 'N' : 'S';
            exifObj.GPS[piexif.GPSIFD.GPSLatitude] = latDMS;
            exifObj.GPS[piexif.GPSIFD.GPSLongitudeRef] = gpsData.longitude >= 0 ? 'E' : 'W';
            exifObj.GPS[piexif.GPSIFD.GPSLongitude] = lonDMS;

            const exifBytes = piexif.dump(exifObj);
            const newData = piexif.insert(exifBytes, fileData);
            const newBase64 = newData.split(',')[1];
            const byteString = atob(newBase64);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: file.type });

            // Create a new File object with the modified EXIF
            modifiedFile = new File([blob], file.name, { type: file.type });
          } catch (error) {
            console.error('Error embedding GPS data into EXIF:', error);
            alert(`Failed to embed GPS data into ${file.name}. Uploading without location information.`);
          }
        }

        return {
          file: modifiedFile,
          baseName: getBaseName(modifiedFile.name),
          extension: getExtension(modifiedFile.name),
          conflict: false,
          progress: 0,
          status: 'pending',
        };
      })
    );

    // Check for filename conflicts
    const filesWithConflictFlags = setConflictFlags(filesWithMetadata, folderItems);

    // Append to selectedFiles
    setSelectedFiles((prev) => [...prev, ...filesWithConflictFlags]);

    // Reset the input so the same file can be selected again if needed
    event.target.value = null;
  };

  // Helpers to parse file name
  const getBaseName = (fullName) => {
    const lastDot = fullName.lastIndexOf('.');
    if (lastDot === -1) return fullName; // no extension
    return fullName.substring(0, lastDot);
  };

  const getExtension = (fullName) => {
    const lastDot = fullName.lastIndexOf('.');
    if (lastDot === -1) return '';
    return fullName.substring(lastDot + 1);
  };

  const sanitizeFileName = (name) =>
    name.replace(/[<>:"/\\|?*]/g, '').trim();

  // ---------------------------------------------------------
  //  Handle user renaming the file (baseName)
  // ---------------------------------------------------------
  const handleBaseNameChange = (index, newBase) => {
    // 1) Sanitize user’s input
    let sanitizedBase = sanitizeFileName(newBase) || `Photo_${Date.now()}`;

    // 2) Update selectedFiles in state
    const updatedFiles = [...selectedFiles];
    const fileObj = updatedFiles[index];
    fileObj.baseName = sanitizedBase;

    // 3) Re-check conflict
    const newFullName = fileObj.extension
      ? `${fileObj.baseName}.${fileObj.extension}`
      : fileObj.baseName;

    const conflict = folderItems.some(
      (item) => item.name.toLowerCase() === newFullName.toLowerCase()
    );
    fileObj.conflict = conflict;

    setSelectedFiles(updatedFiles);
  };

  // Helper to build final full name
  const getFullFileName = (fileObj) => {
    return fileObj.extension
      ? `${fileObj.baseName}.${fileObj.extension}`
      : fileObj.baseName;
  };

  // ---------------------------------------------------------
  //  Update file status
  // ---------------------------------------------------------
  const updateFileStatus = (fileObj, status) => {
    setSelectedFiles((prev) =>
      prev.map((f) => (f === fileObj ? { ...f, status } : f))
    );
  };

  // (We still update progress in the background, but not displayed)
  const updateFileProgress = (fileObj, progress) => {
    setSelectedFiles((prev) =>
      prev.map((f) => (f === fileObj ? { ...f, progress } : f))
    );
  };

  // ---------------------------------------------------------
  //  Periodically check if files have appeared in folder
  // ---------------------------------------------------------
  const startUploadCheck = () => {
    // Clear any old interval
    if (checkIntervalId) {
      clearInterval(checkIntervalId);
    }

    const intervalId = setInterval(async () => {
      try {
        // Fetch the folder items fresh
        const token = await getAccessToken(['Files.ReadWrite.All']);
        const url = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${folderId}/children`;
        const resp = await fetch(url, { headers: { Authorization: `Bearer ${token}` } });
        if (!resp.ok) {
          throw new Error(`Failed to fetch folder items: ${resp.statusText}`);
        }
        const data = await resp.json();

        // Update statuses if the file name is present
        setSelectedFiles((prevFiles) => {
          return prevFiles.map((fileObj) => {
            if (fileObj.status === 'uploading' || fileObj.status === 'pending') {
              const fullName = getFullFileName(fileObj).toLowerCase();
              const found = data.value.some(
                (item) => item.name.toLowerCase() === fullName
              );
              if (found) {
                return { ...fileObj, status: 'uploaded' };
              }
            }
            return fileObj;
          });
        });

        // If all files are done (uploaded or error), clear interval
        setSelectedFiles((prevFiles) => {
          const allDone = prevFiles.every(
            (f) => f.status === 'uploaded' || f.status === 'error'
          );
          if (allDone) {
            clearInterval(intervalId);
            setCheckIntervalId(null);
          }
          return prevFiles;
        });
      } catch (error) {
        console.error('Error checking folder items:', error);
        // In case of any error, clear the interval so we don't loop forever
        clearInterval(intervalId);
        setCheckIntervalId(null);
      }
    }, 5000);

    setCheckIntervalId(intervalId);
  };

  // ---------------------------------------------------------
  //  Handle upload
  // ---------------------------------------------------------
  const handleUpload = async () => {
    // Prevent upload if any file has conflict
    const hasConflict = selectedFiles.some((f) => f.conflict);
    if (hasConflict) {
      alert('One or more files have name conflicts. Please rename them before uploading.');
      return;
    }

    if (!driveId || !folderId) {
      alert('No folder selected (or no root folder found).');
      return;
    }

    // Upload all "pending" files
    const pendingFiles = selectedFiles.filter((f) => f.status === 'pending');
    for (const fileObj of pendingFiles) {
      try {
        updateFileStatus(fileObj, 'uploading');

        await graphService.uploadFileToDriveFolder(
          driveId,
          folderId,
          fileObj.file,
          (progress) => updateFileProgress(fileObj, progress),
          getFullFileName(fileObj) // pass the final name
        );

        // We'll let the periodic "checker" confirm that it's actually in the folder.
      } catch (error) {
        console.error('Error uploading file:', error);
        updateFileStatus(fileObj, 'error');
      }
    }

    // After initiating all uploads, start periodic check
    startUploadCheck();
  };

  // ---------------------------------------------------------
  //  Handle Back Button
  // ---------------------------------------------------------
  const handleBack = () => {
    if (uploadStepVisible) {
      // If we're in the upload step, go back to folders
      setUploadStepVisible(false);
    } else if (selectedChannel) {
      // If we're at folders stage, go back to channel selection
      setSelectedChannel(null);
      setSelectedFolder(null);
    } else if (selectedTeam) {
      // If we're at channel selection, go back to team selection
      setSelectedTeam(null);
      setTeams([]);
      setFilteredTeams([]);
      fetchTeams(); // Re-fetch if desired
    }
  };

  // Build an inline "breadcrumb" heading: Team / Channel / FolderName
  const renderBreadcrumbHeading = () => {
    let folderName = '';
    if (selectedFolder && selectedFolder.name) {
      folderName = ` / ${selectedFolder.name}`;
    }
    return (
      <h3>
        {selectedTeam?.displayName} / {selectedChannel?.displayName}
        {folderName}
      </h3>
    );
  };

  // ---------------------------------------------------------
  //  Render
  // ---------------------------------------------------------
  return (
    <div
      // Flex container: header at top, main content below
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      {/* Header Section */}
      <div style={{ padding: '20px', position: 'relative' }}>
        {/* Show the teams search bar ONLY if no team is selected yet */}
        {!selectedTeam && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              margin: '20px',
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                position: 'absolute',
                top: '-10px',
                left: '-10px',
                maxWidth: '50px',
              }}
            />
            <input
              type="text"
              placeholder="Search Teams"
              value={teamSearch}
              onChange={(e) => setTeamSearch(e.target.value)}
              style={{
                width: 'calc(100% - 60px)',
                padding: '10px',
                fontSize: '16px',
                marginLeft: '45px',
              }}
            />
          </div>
        )}

        {/* Show Back Button if we are beyond the "no team selected" stage */}
        {(selectedTeam || selectedChannel || uploadStepVisible) && (
          <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
            <button
              onClick={handleBack}
              style={{
                padding: '10px 15px',
                backgroundColor: '#cccccc',
                color: '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none',
              }}
            >
              Back
            </button>
          </div>
        )}
      </div>

      {/* Main Content Section (flex:1) */}
      <div style={{ flex: 1, overflow: 'auto', padding: '20px' }}>
        {/* TEAMS LIST */}
        {!selectedTeam && (
          <DataTable
            value={filteredTeams}
            selectionMode="single"
            onSelectionChange={onTeamSelect}
            dataKey="id"
            scrollable
            scrollHeight="100%"
            style={{ marginBottom: '20px' }}
          >
            <Column field="displayName" header="Team Name" />
          </DataTable>
        )}

        {/* CHANNELS LIST */}
        {selectedTeam && !selectedChannel && !uploadStepVisible && (
          <>
            <h3 style={{ textAlign: 'center' }}>
              Select a Channel in {selectedTeam.displayName}
            </h3>
            <input
              type="text"
              placeholder="Search Channels"
              value={channelSearch}
              onChange={(e) => setChannelSearch(e.target.value)}
              style={{
                marginBottom: '10px',
                width: '100%',
                padding: '8px',
              }}
            />
            <DataTable
              value={filteredChannels}
              selectionMode="single"
              onSelectionChange={onChannelSelect}
              dataKey="id"
              scrollable
              scrollHeight="100%"
              style={{ marginBottom: '20px' }}
            >
              <Column field="displayName" header="Channel Name" />
            </DataTable>
          </>
        )}

        {/* FOLDERS/FILES TABLE */}
        {selectedTeam && selectedChannel && !uploadStepVisible && (
          <>
            <div style={{ textAlign: 'center' }}>{renderBreadcrumbHeading()}</div>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              <input
                type="text"
                placeholder="Search Folders"
                value={folderSearch}
                onChange={(e) => setFolderSearch(e.target.value)}
                style={{
                  flex: '1 1 auto',
                  minWidth: '200px',
                  padding: '8px',
                }}
              />

              <button
                onClick={goToUploadStep}
                style={{
                  padding: '12px 24px',
                  fontSize: '16px',
                  backgroundColor: '#0078d4',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                }}
              >
                Proceed to Upload
              </button>
            </div>

            <DataTable
              value={filteredFolders}
              selectionMode="single"
              onSelectionChange={onFolderSelect}
              dataKey="id"
              scrollable
              scrollHeight="100%"
              style={{ marginBottom: '20px' }}
            >
              <Column field="name" header="Folder/File Name" />
            </DataTable>
          </>
        )}

        {/* UPLOAD STEP */}
        {uploadStepVisible && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // container is centered
              textAlign: 'center',
              margin: '0 auto',
              maxWidth: '600px',
            }}
          >
            {renderBreadcrumbHeading()}

            {/* File Input + TOP "Upload Files" button */}
            <div style={{ marginBottom: '20px' }}>
              <input
                type="file"
                accept="image/jpeg"
                capture="environment"
                multiple
                onChange={handleFileSelection}
                style={{ display: 'none' }}
                id="fileInput"
              />
              <label htmlFor="fileInput">
                <span
                  style={{
                    padding: '10px 15px',
                    backgroundColor: '#0078d4',
                    color: '#fff',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  Select/Take Photo
                </span>
              </label>

              <button
                onClick={handleUpload}
                style={{
                  marginLeft: '20px',
                  padding: '10px 15px',
                  backgroundColor: '#28a745',
                  color: '#fff',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: 'none',
                }}
                // We can keep or remove this top button as you prefer:
                disabled={
                  selectedFiles.length === 0 ||
                  selectedFiles.some((fileObj) => fileObj.conflict)
                }
              >
                Upload Files
              </button>
            </div>

            {/* Selected Files - SCROLLABLE and LEFT aligned text */}
            <div
              style={{
                width: '100%',
                maxHeight: '300px', // limit total height
                overflowY: 'auto', // enable scrolling
                marginBottom: '10px',

                textAlign: 'left',
              }}
            >
              {selectedFiles.length === 0 && <p>No photos selected yet.</p>}
              {selectedFiles.map((fileObj, index) => {
                const fullName = fileObj.extension
                  ? `${fileObj.baseName}.${fileObj.extension}`
                  : fileObj.baseName;

                const previewUrl = URL.createObjectURL(fileObj.file);

                // Revoke the object URL after the image has loaded to prevent memory leaks
                const handleImageLoad = () => {
                  URL.revokeObjectURL(previewUrl);
                };

                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      border: '1px solid #ccc',
                      padding: '10px',
                      marginBottom: '10px',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    {/* LEFT SIDE: Original Name, Rename, Conflict Warning, Status */}
                    <div style={{ marginRight: '10px', flex: 1 }}>
                      <p>
                        <strong>Original Name:</strong> {fileObj.file.name}
                      </p>
                      <label>
                        <strong>Rename:</strong>
                        <input
                          type="text"
                          value={fileObj.baseName}
                          onChange={(e) => handleBaseNameChange(index, e.target.value)}
                          style={{
                            marginLeft: '10px',
                            padding: '4px',
                            borderColor: fileObj.conflict ? 'red' : '#ccc',
                          }}
                        />
                      </label>
                      {fileObj.extension && (
                        <span style={{ marginLeft: '8px' }}>
                          .{fileObj.extension}
                        </span>
                      )}

                      {/* Conflict warning */}
                      {fileObj.conflict && (
                        <p style={{ color: 'red', margin: '5px 0 0 0' }}>
                          A file named "{fullName}" already exists in this folder!
                        </p>
                      )}

                      {/* Status Display */}
                      <p>
                        <strong>Status:</strong>{' '}
                        {fileObj.status === 'uploading'
                          ? getUploadingText()
                          : fileObj.status}
                      </p>

                      {fileObj.status === 'error' && (
                        <p style={{ color: 'red' }}>Error uploading</p>
                      )}
                      {fileObj.status === 'uploaded' && (
                        <p style={{ color: 'green' }}>Uploaded successfully!</p>
                      )}
                    </div>

                    {/* RIGHT SIDE: Thumbnail Fills Container */}
                    <div
                      style={{
                        width: '120px',
                        height: '120px',
                        border: '1px solid #ccc',
                        display: 'flex',
                      }}
                    >
                      <img
                        src={previewUrl}
                        alt="Preview"
                        style={{
                          objectFit: 'cover',
                          width: '100%',
                          height: '100%',
                        }}
                        onLoad={handleImageLoad}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* LOADING INDICATOR */}
        {loading && (
          <p style={{ marginTop: '20px', textAlign: 'center' }}>
            <img src={loadingGif} alt="Loading..." style={{ width: '50px' }} />
          </p>
        )}
      </div>

      {/*
        1) Pin the bottom Upload Files button:
           Only shows if we're on the upload step *AND* there's at least 1 file selected.
        2) It's fixed 20px from bottom (and right), so it stays pinned.
      */}
      {uploadStepVisible && selectedFiles.length > 0 && (
        <button
          onClick={handleUpload}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            padding: '10px 20px',
            backgroundColor: '#28a745',
            color: '#fff',
            borderRadius: '5px',
            cursor: 'pointer',
            border: 'none',
          }}
          disabled={selectedFiles.some((fileObj) => fileObj.conflict)}
        >
          Upload Files
        </button>
      )}
    </div>
  );
};

export default TeamsChannelsPicker;
