// src/components/ImageMap.js

import React from 'react';
import GoogleMapReact from 'google-map-react';
import { getAccessToken } from '../utils/auth'; // Make sure this path is correct

class ImageMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],         // Marker objects with driveId, fileId, lat, lng, etc.
      status: '',          // Status overlay text
      isLoading: false,
      logs: [],            // Debug log messages (not displayed in UI)
      viewMode: 'images',  // "images" to show individual markers, "teams" for grouped view
      showProperties: false,
      moveMode: false,     // When true, allows the user to move a marker
      movingMarker: null,  // The marker selected for repositioning
    };

    // Names of your SharePoint GPS columns
    this.latitudeColumn = 'Latitude';
    this.longitudeColumn = 'Longitude';

    // Retry parameters (if needed in additional logic)
    this.maxRetries = 3;
    this.retryDelay = 2000;
  }

  componentDidMount() {
    // Load markers from localStorage if available
    const cachedMarkers = localStorage.getItem('cachedMarkers');
    if (cachedMarkers) {
      this.setState({
        markers: JSON.parse(cachedMarkers),
        isLoading: false,
        status: ''
      });
      this.appendLog('Loaded markers from local cache.');
    }
    // Always refresh markers in the background from the serverless function
    this.loadMarkers();
  }

  componentDidUpdate(prevProps, prevState) {
    // Update localStorage cache when markers change
    if (prevState.markers !== this.state.markers) {
      localStorage.setItem('cachedMarkers', JSON.stringify(this.state.markers));
    }
  }

  appendLog(message) {
    this.setState(prevState => ({ logs: [...prevState.logs, message] }));
    console.log(message);
  }

  async loadMarkers() {
    this.setState({ isLoading: true, status: 'Synchronizing...' });
    this.appendLog('Starting loadMarkers()');
    try {
      // Fetch marker data from your Netlify Function
      const response = await fetch('/.netlify/functions/markers');
      if (!response.ok) {
        throw new Error(`Error fetching markers: ${response.statusText}`);
      }
      const markers = await response.json();
      this.appendLog(`Total markers found: ${markers.length}`);
      this.setState({ markers, status: 'Synchronized.', isLoading: false });
      // Clear status after 30 seconds
      setTimeout(() => {
        this.setState({ status: '' });
      }, 30000);
    } catch (error) {
      this.appendLog(`loadMarkers error: ${error.message}`);
      console.error('loadMarkers error:', error);
      this.setState({ status: `Error: ${error.message}`, isLoading: false });
    }
  }

  // Toggle between "images" and "teams" views
  toggleViewMode = () => {
    this.setState(prevState => ({
      viewMode: prevState.viewMode === 'images' ? 'teams' : 'images'
    }));
  };

  // Toggle display of marker properties (e.g., fileName)
  toggleShowProperties = () => {
    this.setState(prevState => ({
      showProperties: !prevState.showProperties
    }));
  };

  // Enable or disable move mode
  toggleMoveMode = () => {
    this.setState(prevState => ({
      moveMode: !prevState.moveMode,
      movingMarker: null,
      status: !prevState.moveMode
        ? 'Move mode enabled: Click an image marker to select it for moving.'
        : ''
    }));
  };

  // When a marker is clicked in move mode, select it for moving
  handleSelectMarker = (marker) => {
    if (!this.state.moveMode) return;
    this.setState({
      movingMarker: marker,
      status: `Selected "${marker.fileName}". Now click on the map to set a new location.`
    });
  };

  // When the map is clicked and a marker is selected for moving, update its coordinates
  handleMapClick = ({ lat, lng }) => {
    const { movingMarker, markers, moveMode } = this.state;
    if (moveMode && movingMarker) {
      const updatedMarkers = markers.map(m => {
        if (m.fileId === movingMarker.fileId) {
          return { ...m, lat, lng };
        }
        return m;
      });
      this.setState({
        markers: updatedMarkers,
        movingMarker: null,
        moveMode: false,
        status: `Moved image "${movingMarker.fileName}" to new location.`
      });
      // Update GPS data in SharePoint
      this.updateMarkerGPS(movingMarker, lat, lng);
    }
  };

  // Updates the GPS columns in SharePoint for the given marker
  async updateMarkerGPS(marker, newLat, newLng) {
    try {
      const token = await getAccessToken(['Files.ReadWrite.All', 'Sites.ReadWrite.All']);
      if (!token) {
        console.error('No token available for updating GPS.');
        return;
      }
      const patchUrl = `https://graph.microsoft.com/v1.0/drives/${marker.driveId}/items/${marker.fileId}/listItem/fields`;
      const body = {
        [this.latitudeColumn]: newLat.toString(),
        [this.longitudeColumn]: newLng.toString(),
      };
      const resp = await fetch(patchUrl, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
      });
      if (!resp.ok) {
        const txt = await resp.text();
        console.warn(`Failed to update GPS for "${marker.fileName}": ${resp.status} - ${txt}`);
      } else {
        console.log(`GPS updated for "${marker.fileName}".`);
      }
    } catch (error) {
      console.error('updateMarkerGPS error:', error);
    }
  }

  // Groups markers by team (using siteName) for the teams view
  getTeamMarkers() {
    const teams = {};
    this.state.markers.forEach(marker => {
      const team = marker.siteName || 'Unknown';
      if (!teams[team]) {
        teams[team] = { team, latSum: 0, lngSum: 0, count: 0 };
      }
      teams[team].latSum += marker.lat;
      teams[team].lngSum += marker.lng;
      teams[team].count += 1;
    });
    return Object.values(teams).map(teamData => ({
      team: teamData.team,
      lat: teamData.latSum / teamData.count,
      lng: teamData.lngSum / teamData.count,
      count: teamData.count
    }));
  }

  render() {
    const { markers, status, viewMode, showProperties, moveMode } = this.state;
    const initialCenter = { lat: 41.035, lng: -71.863 };
    const defaultZoom = 8;

    // Global button style
    const buttonStyle = {
      background: 'rgba(0, 0, 0, 0.5)',
      color: '#fff',
      padding: '8px 16px',
      borderRadius: '4px',
      fontSize: '14px',
      border: 'none',
      outline: 'none',
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      appearance: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
      marginRight: '8px'
    };

    let markersToDisplay = [];
    if (viewMode === 'images') {
      markersToDisplay = markers;
    } else if (viewMode === 'teams') {
      markersToDisplay = this.getTeamMarkers();
    }

    return (
      <div style={{ height: '100vh', width: '100vw', position: 'relative' }}>
        {/* Toggle Controls */}
        <div
          style={{
            position: 'absolute',
            top: 10,
            left: 10,
            zIndex: 1000,
            background: 'rgba(255,255,255,0.9)',
            padding: '8px',
            borderRadius: '4px'
          }}
        >
          <button onClick={this.toggleViewMode} style={buttonStyle}>
            {viewMode === 'images' ? 'Switch to Team View' : 'Switch to Image View'}
          </button>
          <button onClick={this.toggleMoveMode} style={buttonStyle}>
            {moveMode ? 'Cancel Move Mode' : 'Move Image'}
          </button>
        </div>
        {/* Google Map */}
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAfIUm_hiF_pyuCPn8ifuwE-v2zMx1De-M' }}
          center={initialCenter}
          defaultZoom={defaultZoom}
          onClick={this.handleMapClick}
        >
          {viewMode === 'images'
            ? markersToDisplay.map(marker => (
                <Marker
                  key={`${marker.driveId}-${marker.fileId}`}
                  lat={marker.lat}
                  lng={marker.lng}
                  fileName={marker.fileName}
                  fileUrl={marker.fileUrl}
                  thumbnailUrl={marker.thumbnailUrl}
                  showProperties={showProperties}
                  moveMode={moveMode}
                  onSelectToMove={() => this.handleSelectMarker(marker)}
                />
              ))
            : markersToDisplay.map((teamMarker, index) => (
                <TeamMarker
                  key={index}
                  lat={teamMarker.lat}
                  lng={teamMarker.lng}
                  team={teamMarker.team}
                  count={teamMarker.count}
                />
              ))}
        </GoogleMapReact>
        {status && (
          <div
            style={{
              position: 'absolute',
              bottom: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              background: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              padding: '8px 16px',
              borderRadius: '4px',
              zIndex: 1000
            }}
          >
            {status}
          </div>
        )}
      </div>
    );
  }
}

/**
 * Marker Component
 * Displays a square thumbnail image. In move mode, clicking selects the marker for moving.
 * Otherwise, clicking opens the file URL in a new tab.
 */
const Marker = ({ fileName, fileUrl, thumbnailUrl, showProperties, moveMode, onSelectToMove }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (moveMode) {
      onSelectToMove();
    } else {
      window.open(fileUrl, '_blank');
    }
  };

  return (
    <div
      style={{
        cursor: 'pointer',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
      }}
      title={fileName}
      onClick={handleClick}
    >
      {thumbnailUrl ? (
        <img
          src={thumbnailUrl}
          alt={fileName}
          style={{
            width: '50px',
            height: '50px',
            objectFit: 'cover',
            border: '1px solid #000'
          }}
        />
      ) : (
        <div
          style={{
            width: '50px',
            height: '50px',
            background: '#d00',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {fileName[0]}
        </div>
      )}
      {showProperties && (
        <div
          style={{
            marginTop: '4px',
            background: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            padding: '8px 16px',
            borderRadius: '4px',
            fontSize: '14px',
            display: 'inline-block'
          }}
        >
          {fileName}
        </div>
      )}
    </div>
  );
};

/**
 * TeamMarker Component
 * Displays a marker representing a team, including an icon (using team initials) and the team name.
 */
const TeamMarker = ({ team, count }) => {
  const teamInitials = team
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .substring(0, 2);
  return (
    <div
      style={{
        cursor: 'default',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
      }}
      title={`Team: ${team} (${count} images)`}
    >
      <div
        style={{
          width: '50px',
          height: '50px',
          background: '#0077cc',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '4px',
          border: '2px solid #fff'
        }}
      >
        {teamInitials}
      </div>
      <div
        style={{
          marginTop: '4px',
          background: 'rgba(0, 0, 0, 0.5)',
          color: '#fff',
          padding: '8px 16px',
          borderRadius: '4px',
          fontSize: '14px',
          display: 'inline-block',
          textAlign: 'center'
        }}
      >
        {team}
      </div>
    </div>
  );
};

export default ImageMap;
