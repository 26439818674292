// src/components/ScanGPSPage.js

import React from 'react';
import exifr from 'exifr';
import { getAccessToken } from '../utils/auth'; // Utility for retrieving tokens with the proper scopes

class ScanGPSPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // The overall process: "scanning" then "done"
      step: 'scanning',
      status: '',
      isLoading: false,
      scannedCount: 0,
      totalCount: 0,
    };

    // Column names in SharePoint
    this.latitudeColumn = 'Latitude';
    this.longitudeColumn = 'Longitude';

    // Retry parameters
    this.maxRetries = 20; // to account for provisioning delays
    this.retryDelay = 10000; // 10 seconds initial delay
  }

  componentDidMount() {
    this.autoScanAllTeams();
  }

  /**
   * Automatically scans all teams by:
   *  - Getting all teams the user is a member of.
   *  - For each team, finding the "General" channel.
   *  - Retrieving the channel’s files folder and then a target folder.
   *  - Ensuring the necessary SharePoint columns exist.
   *  - Recursively scanning files to extract GPS data from images.
   */
  autoScanAllTeams = async () => {
    this.setState({ isLoading: true, status: 'Fetching teams...' });
    try {
      // 1. Get the list of teams the user has joined.
      const tokenTeams = await getAccessToken(['Team.ReadBasic.All']);
      if (!tokenTeams) {
        throw new Error('No access token available for Teams');
      }
      const teamsUrl = 'https://graph.microsoft.com/v1.0/me/joinedTeams';
      const teamsResp = await this.fetchWithRetry(
        teamsUrl,
        { headers: { Authorization: `Bearer ${tokenTeams}` } },
        this.maxRetries,
        this.retryDelay
      );
      if (!teamsResp.ok) {
        const txt = await teamsResp.text();
        throw new Error(`Failed to fetch teams: ${teamsResp.status} - ${txt}`);
      }
      const teamsData = await teamsResp.json();
      const teams = teamsData.value || [];
      console.log('Fetched teams:', teams);

      if (teams.length === 0) {
        this.setState({ status: 'No teams found.', isLoading: false, step: 'done' });
        return;
      }

      let overallScannedCount = 0;
      let overallTotalCount = 0;

      // 2. Process each team one by one.
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        this.setState({ status: `Scanning team ${team.displayName} (${i + 1} of ${teams.length})...` });

        // 2a. Get channels for the team.
        const tokenChannels = await getAccessToken(['Channel.ReadBasic.All']);
        if (!tokenChannels) throw new Error('No access token available for Channels.');
        const channelsUrl = `https://graph.microsoft.com/v1.0/teams/${team.id}/channels`;
        const channelsResp = await this.fetchWithRetry(
          channelsUrl,
          { headers: { Authorization: `Bearer ${tokenChannels}` } },
          this.maxRetries,
          this.retryDelay
        );
        if (!channelsResp.ok) {
          const txt = await channelsResp.text();
          console.warn(`Failed to fetch channels for team ${team.displayName}: ${channelsResp.status} - ${txt}`);
          continue;
        }
        const channelsData = await channelsResp.json();
        const channels = channelsData.value || [];

        // 2b. Find the "General" channel (case-insensitive).
        const generalChannel = channels.find(
          (c) => c.displayName.toLowerCase() === 'general'
        );
        if (!generalChannel) {
          console.warn(`General channel not found in team ${team.displayName}. Skipping...`);
          continue;
        }
        console.log(`General channel found in team ${team.displayName}:`, generalChannel);

        // 2c. Get the channel's files folder.
        const tokenFiles = await getAccessToken(['Files.ReadWrite.All', 'Sites.ReadWrite.All']);
        if (!tokenFiles) throw new Error('No token available for Files.');
        const filesFolderUrl = `https://graph.microsoft.com/v1.0/teams/${team.id}/channels/${generalChannel.id}/filesFolder`;
        const filesFolderResp = await this.fetchWithRetry(
          filesFolderUrl,
          { headers: { Authorization: `Bearer ${tokenFiles}` } },
          this.maxRetries,
          this.retryDelay
        );
        if (!filesFolderResp.ok) {
          if (filesFolderResp.status === 404) {
            console.warn(`Files folder not found for team ${team.displayName}. Skipping...`);
          } else {
            const txt = await filesFolderResp.text();
            console.warn(`Failed to fetch files folder for team ${team.displayName}: ${filesFolderResp.status} - ${txt}`);
          }
          continue;
        }
        const filesFolderData = await filesFolderResp.json();
        if (!filesFolderData.id || !filesFolderData.parentReference?.driveId) {
          console.warn(`Invalid files folder data for team ${team.displayName}. Skipping...`);
          continue;
        }
        const driveId = filesFolderData.parentReference.driveId;
        const channelFolderId = filesFolderData.id;
        console.log(`Team ${team.displayName} - General channel Folder ID: ${channelFolderId}, Drive ID: ${driveId}`);

        // 2d. Look for the subfolder "Finished Pictures and Videos" within the channel folder.
        const childrenUrl = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${channelFolderId}/children`;
        const childrenResp = await this.fetchWithRetry(
          childrenUrl,
          { headers: { Authorization: `Bearer ${tokenFiles}` } },
          this.maxRetries,
          this.retryDelay
        );
        if (!childrenResp.ok) {
          const txt = await childrenResp.text();
          console.warn(`Failed to list children in channel folder for team ${team.displayName}: ${childrenResp.status} - ${txt}`);
          continue;
        }
        const childrenData = await childrenResp.json();
        const children = childrenData.value || [];
        const targetFolder = children.find(
          (item) => item.name === 'Finished Pictures and Videos' && item.folder
        );
        if (!targetFolder) {
          console.warn(`Folder "Finished Pictures and Videos" not found in team ${team.displayName} General channel.`);
          continue;
        }
        console.log(`Found target folder in team ${team.displayName}:`, targetFolder);
        const targetFolderId = targetFolder.id;

        // 2e. Get the SharePoint site and Documents library (list) IDs.
        this.setState({ status: `Processing SharePoint for team ${team.displayName}...` });
        const { siteId, listId } = await this.getSharePointSiteAndList(team.id);
        console.log(`Team ${team.displayName} - SharePoint Site ID: ${siteId}, List ID: ${listId}`);

        // 2f. Ensure the GPS columns exist in the SharePoint list.
        this.setState({ status: `Ensuring columns exist for team ${team.displayName}...` });
        await this.ensureColumnsExist(siteId, listId);

        // 2g. Fetch all files recursively from the target folder.
        this.setState({ status: `Fetching files in team ${team.displayName} "Finished Pictures and Videos" folder...` });
        const allItems = await this.fetchAllItemsRecursively(driveId, targetFolderId);
        console.log(`Team ${team.displayName} - Total files to scan: ${allItems.length}`);
        overallTotalCount += allItems.length;

        // 2h. Process each file in the target folder.
        let teamScannedCount = 0;
        for (const fileItem of allItems) {
          await this.processFileForGPS(driveId, fileItem);
          teamScannedCount++;
          overallScannedCount++;
          this.setState({ scannedCount: overallScannedCount, totalCount: overallTotalCount });
        }
      }

      this.setState({
        status: `Scan complete. Scanned ${overallScannedCount} items across ${teams.length} teams.`,
        isLoading: false,
        step: 'done',
      });
    } catch (error) {
      console.error('autoScanAllTeams error:', error);
      this.setState({
        status: `Error scanning teams: ${error.message}`,
        isLoading: false,
        step: 'done',
      });
    }
  };

  /**
   * Modified fetchWithRetry: This version does not retry on 404 errors.
   * It only retries on statuses that are likely transient (e.g., 429, 500, 503).
   */
  fetchWithRetry = async (
    url,
    options,
    retries = 3,
    delay = 2000,
    retryOnStatus = [429, 500, 503]
  ) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        const resp = await fetch(url, options);
        if (resp.ok) return resp;

        // Immediately return on 404 errors.
        if (resp.status === 404) {
          console.warn(`Received 404 for ${url}. Not retrying.`);
          return resp;
        }

        // Retry if the error status is in the retryOnStatus list.
        if (retryOnStatus.includes(resp.status) && attempt < retries) {
          console.warn(`Attempt ${attempt} for ${url} failed with status ${resp.status}. Retrying in ${delay}ms...`);
          await this.sleep(delay);
          delay *= 2;
          continue;
        }
        return resp;
      } catch (error) {
        if (attempt < retries) {
          console.warn(`Attempt ${attempt} encountered error: ${error.message}. Retrying in ${delay}ms...`);
          await this.sleep(delay);
          delay *= 2;
        } else {
          throw error;
        }
      }
    }
    throw new Error(`Failed to fetch ${url} after ${retries} attempts.`);
  };

  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  getSharePointSiteAndList = async (groupId) => {
    const siteId = await this.getSharePointSiteId(groupId);
    const listId = await this.getDocumentsListId(siteId);
    return { siteId, listId };
  };

  getSharePointSiteId = async (groupId) => {
    const token = await getAccessToken(['Sites.ReadWrite.All', 'Files.ReadWrite.All', 'Group.Read.All']);
    if (!token) throw new Error('No token in getSharePointSiteId.');
    const siteUrl = `https://graph.microsoft.com/v1.0/groups/${groupId}/sites/root`;
    const resp = await this.fetchWithRetry(
      siteUrl,
      { headers: { Authorization: `Bearer ${token}` } },
      this.maxRetries,
      this.retryDelay
    );
    if (!resp.ok) {
      const txt = await resp.text();
      throw new Error(`Failed to fetch SharePoint site: ${resp.status} - ${txt}`);
    }
    const siteData = await resp.json();
    console.log('Fetched SharePoint site:', siteData);
    const siteId = siteData.id;
    if (!siteId) throw new Error('No siteId found in SharePoint site data.');
    return siteId;
  };

  getDocumentsListId = async (siteId) => {
    const token = await getAccessToken(['Sites.ReadWrite.All', 'Files.ReadWrite.All', 'Group.Read.All']);
    if (!token) throw new Error('No token in getDocumentsListId.');
    const listsUrl = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists?$filter=displayName eq 'Documents'`;
    const resp = await this.fetchWithRetry(
      listsUrl,
      { headers: { Authorization: `Bearer ${token}` } },
      this.maxRetries,
      this.retryDelay
    );
    if (!resp.ok) {
      const txt = await resp.text();
      throw new Error(`Failed to fetch lists from site: ${resp.status} - ${txt}`);
    }
    const listsData = await resp.json();
    const documentsList = (listsData.value || []).find(
      (list) => list.displayName.toLowerCase() === 'documents'
    );
    if (!documentsList) {
      throw new Error(`"Documents" library not found in site: ${siteId}`);
    }
    console.log('Fetched "Documents" list:', documentsList);
    return documentsList.id;
  };

  ensureColumnsExist = async (siteId, listId) => {
    const token = await getAccessToken(['Sites.Manage.All', 'Files.ReadWrite.All', 'Sites.ReadWrite.All']);
    if (!token) throw new Error('No token in ensureColumnsExist.');
    const colUrl = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${listId}/columns`;
    const resp = await this.fetchWithRetry(
      colUrl,
      { headers: { Authorization: `Bearer ${token}` } },
      this.maxRetries,
      this.retryDelay
    );
    if (!resp.ok) {
      const txt = await resp.text();
      throw new Error(`Failed to retrieve existing columns: ${resp.status} - ${txt}`);
    }
    const colData = await resp.json();
    const existingCols = colData.value || [];
    console.log('Existing Columns:', existingCols);
    const hasLatitude = existingCols.some(
      (c) => c.name?.toLowerCase() === this.latitudeColumn.toLowerCase()
    );
    const hasLongitude = existingCols.some(
      (c) => c.name?.toLowerCase() === this.longitudeColumn.toLowerCase()
    );
    if (!hasLatitude) {
      console.log(`Column "${this.latitudeColumn}" not found. Creating...`);
      await this.createColumn(siteId, listId, this.latitudeColumn);
    }
    if (!hasLongitude) {
      console.log(`Column "${this.longitudeColumn}" not found. Creating...`);
      await this.createColumn(siteId, listId, this.longitudeColumn);
    }
    console.log('ensureColumnsExist completed.');
  };

  createColumn = async (siteId, listId, columnName) => {
    const token = await getAccessToken(['Sites.Manage.All', 'Files.ReadWrite.All', 'Sites.ReadWrite.All']);
    if (!token) throw new Error('No token in createColumn.');
    const body = {
      name: columnName,
      text: {
        allowMultipleLines: false,
        maxLength: 255,
        textType: 'plain',
      },
    };
    const colUrl = `https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${listId}/columns`;
    const resp = await this.fetchWithRetry(
      colUrl,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      this.maxRetries,
      this.retryDelay
    );
    if (!resp.ok) {
      const txt = await resp.text();
      throw new Error(`Failed to create column '${columnName}': ${resp.status} - ${txt}`);
    }
    const createdColumn = await resp.json();
    console.log(`Column "${columnName}" created:`, createdColumn);
  };

  // Recursively fetch all files in a folder (BFS)
  fetchAllItemsRecursively = async (driveId, folderId) => {
    const token = await getAccessToken(['Files.ReadWrite.All', 'Sites.ReadWrite.All']);
    if (!token) throw new Error('No token in fetchAllItemsRecursively.');
    let queue = [folderId];
    let allFiles = [];
    while (queue.length) {
      const currentFolderId = queue.shift();
      const url = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${currentFolderId}/children`;
      const resp = await this.fetchWithRetry(
        url,
        { headers: { Authorization: `Bearer ${token}` } },
        this.maxRetries,
        this.retryDelay
      );
      if (!resp.ok) {
        console.warn(`Skipping folder ${currentFolderId} due to error: ${resp.status}`);
        continue;
      }
      const data = await resp.json();
      const children = data.value || [];
      console.log(`Fetched ${children.length} items from folder ${currentFolderId}`);
      for (const item of children) {
        if (item.folder) {
          queue.push(item.id);
        } else if (item.file) {
          allFiles.push(item);
        }
      }
      // Handle pagination if necessary
      let nextLink = data['@odata.nextLink'];
      while (nextLink) {
        const paginatedResp = await this.fetchWithRetry(
          nextLink,
          { headers: { Authorization: `Bearer ${token}` } },
          this.maxRetries,
          this.retryDelay
        );
        if (!paginatedResp.ok) {
          console.warn(`Skipping pagination for folder ${currentFolderId} due to error: ${paginatedResp.status}`);
          break;
        }
        const paginatedData = await paginatedResp.json();
        const paginatedChildren = paginatedData.value || [];
        console.log(`Fetched ${paginatedChildren.length} paginated items from folder ${currentFolderId}`);
        for (const item of paginatedChildren) {
          if (item.folder) {
            queue.push(item.id);
          } else if (item.file) {
            allFiles.push(item);
          }
        }
        nextLink = paginatedData['@odata.nextLink'];
      }
    }
    this.setState({ totalCount: allFiles.length });
    console.log(`Total files to scan: ${allFiles.length}`);
    return allFiles;
  };

  // Process a single file: check for existing GPS data, extract from EXIF if needed, and update SharePoint.
  processFileForGPS = async (driveId, fileItem) => {
    try {
      // Skip file if it already has GPS data.
      const alreadyHasGPS = await this.fileAlreadyHasGPS(fileItem);
      if (alreadyHasGPS) {
        console.log(`File "${fileItem.name}" already has GPS data.`);
        return;
      }
      // If the file is an image, try to extract EXIF GPS data.
      const coords = await this.extractGPSIfAny(driveId, fileItem);
      if (!coords) {
        console.log(`No GPS data found in "${fileItem.name}".`);
        return;
      }
      console.log(`GPS data found in "${fileItem.name}":`, coords);
      // Update SharePoint list columns.
      await this.updateGPSColumns(fileItem, coords);
    } catch (err) {
      console.error(`Error processing file "${fileItem.name}":`, err);
    }
  };

  fileAlreadyHasGPS = async (fileItem) => {
    const token = await getAccessToken(['Files.ReadWrite.All', 'Sites.ReadWrite.All']);
    if (!token) throw new Error('No token in fileAlreadyHasGPS.');
    const url = `https://graph.microsoft.com/v1.0/drives/${fileItem.parentReference.driveId}/items/${fileItem.id}?expand=listItem($select=fields)`;
    const resp = await this.fetchWithRetry(
      url,
      { headers: { Authorization: `Bearer ${token}` } },
      this.maxRetries,
      this.retryDelay
    );
    if (!resp.ok) {
      console.warn(`Skipping GPS check for "${fileItem.name}", error: ${resp.status}`);
      return false;
    }
    const data = await resp.json();
    const fields = data?.listItem?.fields;
    if (!fields) return false;
    const latVal = fields[this.latitudeColumn];
    const lngVal = fields[this.longitudeColumn];
    return !!(latVal && lngVal);
  };

  extractGPSIfAny = async (driveId, fileItem) => {
    // Only process image files (jpg/jpeg/png).
    if (!/\.(jpg|jpeg|png)$/i.test(fileItem.name)) {
      return null;
    }
    const token = await getAccessToken(['Files.ReadWrite.All', 'Sites.ReadWrite.All']);
    if (!token) throw new Error('No token in extractGPSIfAny.');
    const downloadUrl = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${fileItem.id}/content`;
    const resp = await this.fetchWithRetry(
      downloadUrl,
      { headers: { Authorization: `Bearer ${token}` } },
      this.maxRetries,
      this.retryDelay
    );
    if (!resp.ok) {
      console.warn(`Failed to download "${fileItem.name}": ${resp.status}`);
      return null;
    }
    const blob = await resp.blob();
    const exifData = await exifr.parse(blob);
    if (exifData?.latitude && exifData?.longitude) {
      return { latitude: exifData.latitude, longitude: exifData.longitude };
    }
    return null;
  };

  updateGPSColumns = async (fileItem, coords) => {
    const token = await getAccessToken(['Files.ReadWrite.All', 'Sites.ReadWrite.All']);
    if (!token) throw new Error('No token in updateGPSColumns.');
    const patchUrl = `https://graph.microsoft.com/v1.0/drives/${fileItem.parentReference.driveId}/items/${fileItem.id}/listItem/fields`;
    const body = {
      [this.latitudeColumn]: coords.latitude.toString(),
      [this.longitudeColumn]: coords.longitude.toString(),
    };
    const resp = await this.fetchWithRetry(
      patchUrl,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      this.maxRetries,
      this.retryDelay
    );
    if (!resp.ok) {
      const txt = await resp.text();
      console.warn(`Failed to update GPS for "${fileItem.name}": ${resp.status} - ${txt}`);
    } else {
      console.log(`GPS updated for "${fileItem.name}".`);
    }
  };

  // ---------------------- Render UI ----------------------
  render() {
    const { step, status, isLoading, scannedCount, totalCount } = this.state;
    return (
      <div style={{ padding: '20px' }}>
        <h2>Automated GPS Scan for All Teams</h2>
        {status && (
          <p>
            <strong>Status:</strong> {status}
          </p>
        )}
        {isLoading && <p>Loading...</p>}
        {step === 'scanning' && totalCount > 0 && (
          <p>
            Scanned {scannedCount} / {totalCount} files
          </p>
        )}
        {step === 'done' && (
          <div>
            <h3>Scan Complete</h3>
            <p>{status}</p>
          </div>
        )}
      </div>
    );
  }
}

export default ScanGPSPage;
