// src/components/Dashboard.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../components/logo.jpg'; // Adjust to your actual logo path
import './Dashboard.css';

function Dashboard() {
  const navigate = useNavigate();

  return (
    <div className="dashboard-container">
      {/* LOGO */}
      <img src={logo} alt="Logo" className="dashboard-logo" />

      {/* BUTTONS */}
      <div className="dashboard-buttons">
        <button
          className="dashboard-button"
          onClick={() => navigate('/search')}
        >
          Spreadsheet Search
        </button>
        <button
          className="dashboard-button"
          onClick={() => navigate('/teams-channels')}
        >
          Teams Channels
        </button>
        <button
          className="dashboard-button"
          onClick={() => navigate('/scan-gps')}
        >
          GPS Scan
        </button>
        {/* NEW: Button for the Image Map */}
        <button
          className="dashboard-button"
          onClick={() => navigate('/image-map')}
        >
          Image Map
        </button>
      </div>
    </div>
  );
}

export default Dashboard;
